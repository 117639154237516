import { Box, Container, Flex, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import {  
  ListItem,  
  UnorderedList,
  Divider 
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ExternalLinkIcon } from "@chakra-ui/icons";

interface TaskTemplateProps {
  children: ReactNode;
  taskNumber: string;
  title: string;
  description1: string;
  description2: string;
  participants:string;
  metrics: string;
  contributor: string;
}

const TaskTemplate = ({ children, taskNumber, title, description1, description2, participants, metrics, contributor}: TaskTemplateProps ) => (
  <Box minH="calc(100vh - 94px)" bg="black" p={{ base: 3, md: 12 }}>
    <Container maxW={1440}>
    <Text fontSize="xl">{taskNumber}</Text>
    <Text fontSize="4xl" fontWeight="bold" my={2}>{title}</Text>
      <Flex gap={16} direction={{ base: "column", lg: "row" }}>
      <Box flex={2}>
        <Box fontSize="lg" lineHeight={2} mb={4}>
          <Text as="p" mb={2}>
            {description1}{" "}
          </Text>
          <Text as="p" mb={2}>
            {description2}
          </Text>
          <Box>
            <UnorderedList>
              <ListItem>
                Participants: {participants}
              </ListItem>
              <ListItem>
                Your Rank: {metrics}
              </ListItem>
            </UnorderedList>
          </Box>
        </Box>
        <Divider my={6} />
        <Box>
          <Text fontSize="xl" fontWeight="bold" my={4}>
            Data Contribute to
          </Text>
          <Flex align="center">
            <Text fontWeight={300} pr={2}>
              {contributor}{"{0x(0000)}"}
            </Text>
            <Link to="/dna/1">
              <ExternalLinkIcon />
            </Link>
          </Flex>
        </Box>
      </Box>
      {children}
      </Flex>
    </Container>
  </Box>
);

export default TaskTemplate;