import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { connectorsForWallets, RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import {
  metaMaskWallet,
  walletConnectWallet
} from '@rainbow-me/rainbowkit/wallets';
import { createConfig, http, WagmiProvider } from 'wagmi';
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import '@rainbow-me/rainbowkit/styles.css';
import { arbitrum, arbitrumSepolia } from "viem/chains";
import { createClient } from 'viem'

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [ metaMaskWallet, walletConnectWallet ],
    },
  ],
  {
    appName: '0xer',
    projectId: 'bde949693365224cc4ec29edbe87e304',
  }
);

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || '421613'

// 定義支援的鏈，使用數字類型作為鍵
const chainMap: { [key: string]: any } = {
  '421613': arbitrumSepolia, // Arbitrum Sepolia
  '42161': arbitrum,         // Arbitrum Mainnet
}

const selectedChain = chainMap[CHAIN_ID] || arbitrumSepolia

const config = createConfig({
  connectors,
  chains: [selectedChain],
  client({ chain }) {
    return createClient({ chain, transport: http() })
  },
});


const queryClient = new QueryClient();

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <GoogleReCaptchaProvider reCaptchaKey="6LdWFlYnAAAAAHE2RE4GHQcI4qQ3I74sLGtAYAqn">
        <ChakraProvider theme={theme}>
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
              <RainbowKitProvider theme={darkTheme()}>
                <App />
              </RainbowKitProvider>
            </QueryClientProvider>
          </WagmiProvider>
        </ChakraProvider>
      </GoogleReCaptchaProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
