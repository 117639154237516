import {
  Box,
  Button,
  Flex,
  Image,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import useScrollToTop from "@/libs/useScrollToTop";
import { useCallback, useContext, useEffect, useState } from "react";
import AccountContext from "@/context/account";
import recaptchaLogo from "@/assets/images/recaptcha.png";
import correctIcon from "@/assets/images/correct.png";
import incorrectIcon from "@/assets/images/incorrect.png";
import { answerQuestion, getLeaderboard, getQuestions, getUser } from "@/api";
import counterImage from "@/assets/images/counter.png";
import Countdown from "@/components/Countdown";
import TaskTemplate from "@/pages/Tasks/TaskTemplate";
import config from "@/pages/Tasks/TaskConfig.json";

enum STATUSES {
  IDLE,
  STARTED,
  SCORE,
}

const FORCE_FAILED_OPTION = -2;

const KnowledgeTest = () => {
  useScrollToTop();
  const { account, accountInfo, id } = useContext(AccountContext);
  const [startAt, setStartAt] = useState(0);
  const [status, setStatus] = useState(STATUSES.IDLE);
  const [totalResponseTime, setTotalResponseTime] = useState(0);
  const [total, setTotal] = useState<number>();
  const [rank, setRank] = useState<number>();
  const [saved, setSaved] = useState<
    Array<{
      correct: boolean;
      index: number;
      responseTime: number;
    }>
  >([]);
  const [current, setCurrent] = useState(-1);
  const [selected, setSelected] = useState(-1);
  const [score, setScore] = useState(0);
  const [questions, setQuestions] = useState<
    Array<{
      description: string;
      answer: number;
      options: string[];
      image: string;
    }>
  >([]);
  const question = questions[current];
  const progress = saved.length;
  const task3 = config[2];

  const findRandomUnsolvedQuestion = useCallback(
    (data: Array<{ index: number }>) => {
      const solved = data.reduce((acc: any[], cur) => [...acc, cur.index], []);
      const unsolved = Array.from({ length: questions.length })
        .map((_, index) => index)
        .filter((i) => !solved.includes(i));
      const randomIndex = Math.floor(unsolved.length * Math.random());
      return unsolved[randomIndex];
    },
    [questions]
  );

  const start = useCallback(() => {
    setSelected(-1);
    setStartAt(Date.now());
    if (saved.length !== questions.length) {
      setStatus(STATUSES.STARTED);
      setCurrent(findRandomUnsolvedQuestion(saved));
    } else setStatus(STATUSES.SCORE);
  }, [saved, questions.length, findRandomUnsolvedQuestion]);

  const onSelect = useCallback(
    (value: number) => async () => {
      setSelected(value);
      const responseTime = Date.now() - startAt;
      setTotalResponseTime(totalResponseTime + responseTime);
      if (value === question.answer) {
        setScore(score + 1);
      }
      try {
        await answerQuestion(current, value, responseTime);

        setTimeout(() => {
          setStartAt(Date.now());
          setSelected(-1);
          const newSaved = [
            ...saved,
            {
              correct: value === question.answer,
              index: current,
              responseTime,
            },
          ];
          setSaved(newSaved);
          setCurrent(findRandomUnsolvedQuestion(newSaved));
          if (newSaved.length === questions.length) {
            setStatus(STATUSES.SCORE);
          }
        }, 1000);
      } catch (e) {
        console.error(e);
      }
    },
    [
      current,
      findRandomUnsolvedQuestion,
      question,
      questions,
      saved,
      score,
      startAt,
      totalResponseTime,
    ]
  );

  const computeSelectedStyle = useCallback(
    (
      index: number,
      styles: { correct: string; normal: string; incorrect: string }
    ) => {
      switch (true) {
        case selected === index && selected === question.answer:
        case selected !== -1 && index === question.answer:
          return styles.correct;
        case selected !== index && selected !== -1:
          return styles.normal;
        case selected !== -1 && index !== question.answer:
          return styles.incorrect;
      }
    },
    [question, selected]
  );

  useEffect(() => {
    if (account && accountInfo) {
      Promise.all([getQuestions(), getUser(), getLeaderboard(0xc0)]).then(
        ([
          { data },
          { web3_test_results: web3TestResults = [] },
          {
            data: { length },
            user,
          },
        ]) => {
          setQuestions(data);
          setSaved(web3TestResults);
          setTotalResponseTime(
            web3TestResults.reduce(
              (acc: number, cur: any) => acc + cur.response_time,
              0
            )
          );
          setScore(
            web3TestResults.reduce(
              (acc: number, cur: any) => acc + +cur.correct,
              0
            )
          );
          setTotal(length || 0);
          setRank(user?.rank);
        }
      );
    }
  }, [account, accountInfo]);

  // auto select if user close the window
  useEffect(() => {
    if (status === STATUSES.STARTED) {
      const handler = (e: any) => {
        e.preventDefault();

        const responseTime = Date.now() - startAt;
        answerQuestion(current, FORCE_FAILED_OPTION, responseTime);

        e.returnValue = true;
      };
      window.addEventListener("beforeunload", handler);
      return () => window.removeEventListener("beforeunload", handler);
    }
  }, [current, startAt, status]);

  return (   
    <TaskTemplate
      taskNumber={task3.taskNumber}
      title={task3.title}
      description1={task3.description1 || ""}
      description2={task3.description2 || ""}                
      participants = {total ? total.toLocaleString() : "--"} 
      metrics={rank ? rank.toLocaleString() : "--"}
      contributor={task3.contributor}
    >     
      <Box flex={3} maxW={500}>
        <VStack alignItems="stretch" gap={10}>
          {(() => {
            if (!id) return;
            switch (status) {
              case STATUSES.IDLE:
                return (
                  <Box>
                    <Text fontSize="xl" fontWeight={500} mb={4}>
                      Start your challenge now!
                    </Text>
                    <Progress
                      mb={3}
                      value={(100 * progress) / (questions.length || 1)}
                      borderColor="accent"
                      bg="#21221D"
                      borderWidth={2}
                      colorScheme="none"
                    />
                    <Text mb={4} letterSpacing={1}>
                      Progress | {progress} / {questions.length}
                    </Text>
                    <Box
                      bg="#21221D"
                      borderWidth={2}
                      borderColor="accent"
                      px={{ base: 5, md: 8 }}
                      py={{ base: 5, md: 6 }}
                    >
                      <Image
                        src={recaptchaLogo}
                        display={{ base: "none", md: "block" }}
                        width="40px"
                        mb={4}
                      />
                      <Text fontWeight="bold" fontSize="lg" mb={4}>
                        Web3 Knowledge Test (100 questions in total)
                      </Text>
                      <Text mb={8}>
                        Below are multiple-choice questions with four
                        options each, provided randomly. Each question has
                        only one correct answer. If you pause midway, you
                        can resume from where you left off, but your
                        previous scores will not be reset.
                      </Text>
                      <Button
                        onClick={start}
                        variant="outlineDark"
                        width="100%"
                      >
                        {progress === 0 && "Start"}
                        {0 < progress &&
                          progress < questions.length &&
                          "Continue"}
                        {progress >= questions.length && "Your Score"}
                      </Button>
                    </Box>
                  </Box>
                );
              case STATUSES.STARTED:
                return (
                  <Box>
                    <Text
                      role="button"
                      textDecor="underline"
                      align="end"
                      mb={6}
                      onClick={() => setStatus(STATUSES.SCORE)}
                    >
                      Stop
                    </Text>
                    <Box
                      bg="#21221D"
                      borderWidth={2}
                      borderColor="accent"
                      px={{ base: 5, md: 8 }}
                      py={{ base: 5, md: 6 }}
                    >
                      <Flex gap={5}>
                        <Box>
                          <Box
                            width={"60px"}
                            height={"60px"}
                            position="relative"
                          >
                            <Image src={counterImage} width={"60px"} />
                            <Text
                              position="absolute"
                              top={0}
                              width={"60px"}
                              align="center"
                              lineHeight={"60px"}
                            >
                              <Countdown
                                from={10}
                                resetTrigger={progress}
                                onFinish={onSelect(FORCE_FAILED_OPTION)}
                              />
                            </Text>
                          </Box>
                        </Box>
                        <Box>
                          <Text mb={2} fontWeight={500}>
                            #{progress + 1}/{questions.length}
                          </Text>
                          <Text mb={4} fontWeight={500}>
                            {question.description}
                          </Text>
                          <Image
                            src={question.image}
                            width={140}
                            height={140}
                          />
                        </Box>
                      </Flex>

                      {question.options.map((option, index) => (
                        <Button
                          key={index}
                          onClick={onSelect(index)}
                          variant="outlineDark"
                          width="100%"
                          mt={4}
                          color={computeSelectedStyle(index, {
                            correct: "white !important",
                            incorrect: "white !important",
                            normal: "white",
                          })}
                          bg={computeSelectedStyle(index, {
                            correct: "#67AE3C !important",
                            incorrect: "#B00C0C !important",
                            normal: "transparent",
                          })}
                          borderColor={computeSelectedStyle(index, {
                            correct: "#67AE3C",
                            incorrect: "#B00C0C",
                            normal: "white",
                          })}
                          _hover={{
                            bg: "white",
                            color: "black",
                          }}
                          isDisabled={selected !== -1}
                        >
                          {option}
                          {selected === index && (
                            <Box position="absolute" right={5}>
                              <Image
                                src={
                                  index === question.answer
                                    ? correctIcon
                                    : incorrectIcon
                                }
                                width="16px"
                              />
                            </Box>
                          )}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                );
              case STATUSES.SCORE:
                return (
                  <Box>
                    <Text fontSize="4xl" fontWeight={500} mb={10}>
                      Your Score
                    </Text>
                    <Text fontSize="lg" fontWeight={500} mb={4}>
                      Answered Questions
                    </Text>
                    <Progress
                      mb={3}
                      value={(100 * progress) / (questions.length || 1)}
                      borderColor="accent"
                      bg="#21221D"
                      borderWidth={2}
                      colorScheme="none"
                    />
                    <Text mb={4} letterSpacing={1}>
                      Progress | {progress} / {questions.length}
                    </Text>
                    <Flex
                      mb={6}
                      gap={4}
                      flexDir={{ base: "column", md: "row" }}
                    >
                      <Box
                        bg="#21221D"
                        flex={1}
                        py={10}
                        borderWidth={2}
                        borderColor="accent"
                      >
                        <Text fontSize="3xl" align="center">
                          {((score / (progress || 1)) * 100).toFixed(1)}%
                        </Text>
                        <Text
                          fontSize="md"
                          fontWeight={300}
                          letterSpacing={1}
                          align="center"
                        >
                          Correctness Rate(%)
                        </Text>
                      </Box>
                      <Box
                        bg="#21221D"
                        flex={1}
                        py={10}
                        borderWidth={2}
                        borderColor="accent"
                      >
                        <Text fontSize="3xl" align="center">
                          {(
                            totalResponseTime /
                            1000 /
                            (questions.length || 1)
                          ).toFixed(2)}
                        </Text>
                        <Text
                          fontSize="md"
                          fontWeight={300}
                          letterSpacing={1}
                          align="center"
                        >
                          AVG. Response Time(S)
                        </Text>
                      </Box>
                    </Flex>
                    {questions.length > progress && (
                      <Text mb={6}>
                        You still have {questions.length - progress}{" "}
                        questions unanswered. You can press the 'Continue'
                        button to resume the challenge.
                      </Text>
                    )}

                    <Button
                      onClick={() => setStatus(STATUSES.IDLE)}
                      variant="outlineDark"
                      width="100%"
                    >
                      OK
                    </Button>
                  </Box>
                );
            }
          })()}
        </VStack>
      </Box>     
    </TaskTemplate>  
  );
};

export default KnowledgeTest;
