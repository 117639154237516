import {
  ComponentType,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  useAccount,
  useAccountEffect,
  useSignMessage,
  useSwitchChain,
} from "wagmi";
import { useToast } from "@chakra-ui/react";
import { CHAIN_ID } from "@/utils/constants";
import useTokenId from "@/libs/useTokenId";
import { createUser, getUser } from "@/api";

interface AccountInfo {
  data: string;
  updatedAt?: number;
}
interface AccountContextType {
  account?: string;
  setAccount: (account: string) => void;
  fromRainbowkit: boolean | undefined;
  id?: number | undefined;
  accountInfo?: AccountInfo;
  auth?: string | null;
  setAuth: (auth: string | null) => void;
}

const AccountContext = createContext<AccountContextType>({
  account: undefined,
  setAccount: () => {},
  fromRainbowkit: false,
  id: undefined,
  accountInfo: undefined,
  auth: null,
  setAuth: () => {},
});

const withAccountContext = (Component: ComponentType) => (props: any) => {
  const { chain } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const { switchChain } = useSwitchChain();
  const [account, setAccount] = useState<string>();
  const [fromRainbowkit, setFromRainbowkit] = useState<boolean>();
  const [signature, setSignature] = useState<string>();
  const [accountInfo, setAccountInfo] = useState<AccountInfo>();
  const toast = useToast();
  const { tokenId } = useTokenId(account || "");
  const [id, setId] = useState<number | undefined>();
  const [auth, setAuth] = useState<string | null>(null);

  const autoSign = useCallback(
    async (account: string) => {
      try {
        const result = await signMessageAsync({
          message: `Hello, ${account}!`,
        });
        if (result) {
          toast({
            title: "Message signed successfully!",
            status: "success",
          });
          setSignature(result);
        }
      } catch (error) {
        toast({
          title: "Failed to sign message, please reconect your wallet.",
          status: "error",
        });
      }
    },
    [signMessageAsync, toast]
  );

  useEffect(() => {
    if (chain && chain.id !== CHAIN_ID) {
      toast({
        title: "Wrong network detected, please switch to Arbitrum network.",
        duration: 5000,
        status: "error",
      });
      switchChain?.({ chainId: CHAIN_ID });
    }
  }, [chain, toast, switchChain]);

  useEffect(() => {
    setId(tokenId);
  }, [tokenId]);

  useAccountEffect({
    onConnect(data) {
      setAccount(data.address);
      setFromRainbowkit(true);
      autoSign(data.address);
    },
    onDisconnect() {
      setAccount(undefined);
      setFromRainbowkit(false);
      setSignature(undefined);
      setId(undefined);
      setAccountInfo(undefined);
      localStorage.removeItem("auth");
    },
  });

  useEffect(() => {
    const login = async () => {
      const result = await createUser({
        address: account,
        signature,
        referrer: undefined,
      });
      localStorage.setItem("auth", result.access_token);
      setAuth(result.access_token);
    };

    if (account && signature) {
      login();
    }
  }, [account, signature]);

  useEffect(() => {
    if (auth) {
      getUser().then(({ data, updated_at: updatedAt }) => {
        setAccountInfo({ data, updatedAt });
      });
    }
  }, [auth]);

  return (
    <AccountContext.Provider
      value={{
        account,
        setAccount,
        fromRainbowkit,
        id,
        accountInfo,
        auth,
        setAuth
      }}
    >
      <Component {...props} />
    </AccountContext.Provider>
  );
};

export default AccountContext;
export { withAccountContext };
