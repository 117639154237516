import {
  Box,
  Button,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import useScrollToTop from "@/libs/useScrollToTop";
import { useCallback, useContext, useEffect, useState } from "react";
import AccountContext from "@/context/account";
import createInviteUrl from "@/libs/createInviteUrl";
import ReferralsOverview from "@/components/ReferralsOverview";
import { getLeaderboard, getUser } from "@/api";
import Web3 from "web3";
import TaskTemplate from "@/pages/Tasks/TaskTemplate";
import config from "@/pages/Tasks/TaskConfig.json";

const Referral = () => {
  useScrollToTop();
  const { account, id, accountInfo } = useContext(AccountContext);
  const [references, setReferences] = useState([]);
  const [total, setTotal] = useState<number>();
  const [rank, setRank] = useState<number>();
  const toast = useToast();
  const task2 = config[1];

  const copyUrl = useCallback(() => {
    if (id) {
      navigator.clipboard.writeText(createInviteUrl(id));
    }
    toast({
      title: "Copied to clipboard!",
      duration: 2000,
      status: "success",
    });
  }, [id, toast]);

  useEffect(() => {
    if (account && accountInfo) {
      getUser().then((user) => {
        setReferences(
          user.references?.map(({ id, joined_at: joinedAt }: any) => ({
            address: Web3.utils.toChecksumAddress(id),
            joinedAt,
          }))
        );
      });
      getLeaderboard(0x50).then(({ user, data }) => {
        setTotal(data?.length || 0);
        setRank(user?.rank);
      });
    }
  }, [account, accountInfo]);

  return (
    <TaskTemplate
      taskNumber={task2.taskNumber}
      title={task2.title}
      description1={task2.description1 || ""}
      description2={task2.description2 || ""}
      participants={total ? total.toLocaleString() : "--"}
      metrics={rank ? rank.toLocaleString() : "--"}
      contributor={task2.contributor}
    >
      <Box flex={3} maxW={620}>
        <VStack alignItems="stretch" gap={10}>
          {id && (
            <>
              <Box>
                <Text fontSize="xl" fontWeight={500} mb={4}>
                  Main Task
                </Text>
                <Text fontSize="xl" fontWeight={500} mb={4}>
                  Copy and share your personal invite link with other humans.
                </Text>
                <VStack
                  bg="#21221D"
                  borderWidth={2}
                  borderColor="accent"
                  px={{ base: 5, md: 8 }}
                  py={{ base: 5, md: 6 }}
                  alignItems="stretch"
                >
                  <Text fontWeight="bold" fontSize="lg" mb={4}>
                    Invite other humans
                  </Text>
                  <Text mb={8}>
                    You can share it on social media or through any means with
                    humans you think are great. Once they join, your invitation
                    score and ranking will increase. At the same time, you will
                    establish a "connection" with them, serving as part of your
                    social graph in 0xer Space.
                  </Text>
                  <Button
                    onClick={copyUrl}
                    variant="outlineDark"
                    bg="white"
                    color="black"
                    fontWeight={300}
                    display="flex"
                    flexDir={{ base: "column", md: "row" }}
                    py={2}
                    height="auto"
                    _hover={{
                      bg: "white",
                    }}
                  >
                    <Text maxW="100%" whiteSpace="normal">
                      {createInviteUrl(id)}
                    </Text>
                    <CopyIcon
                      mx={{ base: "auto", md: 2 }}
                      mt={{ base: 2, md: 0 }}
                      display={{ base: "block", md: "inline" }}
                      verticalAlign={-1}
                    />
                  </Button>
                </VStack>
              </Box>
              <ReferralsOverview data={references} />
            </>
          )}
        </VStack>
      </Box>
    </TaskTemplate>
  );
};

export default Referral;
